<template>
  <div>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Zapier Integration</h1>
      <p>
        Use our Zapier integration to easily synchronize many different data sources with your challenge!
      </p>
      <p>
        Use Zapier integration to easily connect your challenge with the file that contains your challenge participants. 
        Once enabled, all changes to your file will be automatically reflected in your challenge. Simply add a new row in your Google Sheet or Excel file to invite a new participant to your challenge!
      </p>
      <p>
        Zapier integration is available as part of <router-link to="/pricing">Pro plans</router-link> only.
      </p>
      <h3>How it works</h3>
      <ol class="mb-4">
        <li><router-link :to="{name: 'eventmanagerCreate'}">Create your event</router-link></li>
        <li>Copy the API key from the Integrations page</li>
        <li>Create <a href="https://zapier.com" target="_blank">a zap on Zapier.com</a></li>
        <li>Select your data source as Trigger for the zap</li>        
        <li>Add {{tenant.name}} as Action for your zap and map your columns to the registration fields</li>
      </ol>
      <p>
        <strong>That's it, new data will be automatically synced!</strong>
      </p>
    </div>

    <div>
      <h2 class="subheader mt-8">Sample Integration with Zapier</h2>
            <!-- todo: add iframed zap templates -->
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" :color="$helpers.getGradientColorAt('#00B46E', '#FFEB3B', 0)">
            <h3 class="my-4">Google Sheets</h3>
            <p>Sync with a Google Sheets spreadsheet.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" :color="$helpers.getGradientColorAt('#00B46E', '#FFEB3B', 0.2)">
            <h3 class="my-4">Microsoft Excel</h3>
            <p>Sync with a Microsoft Excel spreadsheet.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" :color="$helpers.getGradientColorAt('#00B46E', '#FFEB3B', 0.4)">
            <h3 class="my-4">Google Forms</h3>
            <p>Sync with Google Form.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" :color="$helpers.getGradientColorAt('#00B46E', '#FFEB3B', 0.6)">
            <h3 class="my-4">Typeform</h3>
            <p>Sync with Typeform.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" :color="$helpers.getGradientColorAt('#00B46E', '#FFEB3B', 0.8)">
            <h3 class="my-4">SurveyMonkey</h3>
            <p>Sync with SurveyMonkey.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" :color="$helpers.getGradientColorAt('#00B46E', '#FFEB3B', 1)">
            <h3 class="my-4">Many more!</h3>
            <p>Thousands of more data sources available on <a href="https://zapier.com" target="_blank">Zapier.com</a>!</p>
          </v-sheet>
        </v-col>
      </v-row>

    </div>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="" color="white" href="/contact">Contact Us</v-btn>
              <v-btn class="ml-4" color="white" outlined href="/request-demo">Request Free Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>